var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible:
          _vm.enablePermissionsDialog && _vm.enablePermissionsDialog.visible,
        "before-close": _vm.dialogBeforeClose,
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        top: "0",
        width: "423px",
        "custom-class": "enablePermissionsDialog"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.enablePermissionsDialog && _vm.enablePermissionsDialog,
            "visible",
            $event
          )
        }
      }
    },
    [
      _c("div", { staticClass: "dialogBody" }, [
        _c("div", { staticClass: "dialogTitle" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("notice")) + "\n    ")
        ]),
        _c("div", { staticClass: "bodyContent" }, [
          _c("div", { staticClass: "tipsIcon" }, [
            _c("span", { staticClass: "iconfont_Me icon-remind iconStyle" })
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("div", { staticClass: "tipsTitle" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.type == 1
                      ? _vm.$t("noCameraAuthorized")
                      : _vm.type == 2
                      ? _vm.$t("noAudioAuthorized")
                      : _vm.$t("noShareAuthorized")
                  ) +
                  "\n        "
              )
            ]),
            _c(
              "div",
              { staticClass: "tipsContent" },
              [
                _vm.isMac
                  ? [
                      _vm.type == 1
                        ? [
                            _vm.systemPermissions_camera
                              ? [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("videoHasBeenDeniedTips")) +
                                      "\n              "
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("cameraAuthorizedTips_mac")
                                      ) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "stress",
                                      on: { click: _vm.openSystempreferences }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("systemPreferences"))
                                      )
                                    ]
                                  )
                                ]
                          ]
                        : _vm._e(),
                      _vm.type == 2
                        ? [
                            _vm.systemPermissions_microphone
                              ? [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("audioHasBeenDeniedTips")) +
                                      "\n              "
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("audioAuthorizedTips_mac")
                                      ) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "stress",
                                      on: { click: _vm.openSystempreferences }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("systemPreferences"))
                                      )
                                    ]
                                  )
                                ]
                          ]
                        : _vm._e(),
                      _vm.type == 3
                        ? [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("shareAuthorizedTips_mac")) +
                                "\n              "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "stress",
                                on: { click: _vm.openSystempreferences }
                              },
                              [_vm._v(_vm._s(_vm.$t("systemPreferences")))]
                            )
                          ]
                        : _vm._e()
                    ]
                  : [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.type == 1
                              ? _vm.$t("cameraAuthorizedTips_windows")
                              : _vm.$t("audioAuthorizedTips_windows")
                          ) +
                          "\n          "
                      )
                    ]
              ],
              2
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }