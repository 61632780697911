<template>
  <el-dialog
    :visible.sync="enablePermissionsDialog && enablePermissionsDialog.visible"
    :before-close="dialogBeforeClose"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :top="'0'"
    :width="'423px'"
    custom-class="enablePermissionsDialog"
  >
    <div class="dialogBody">
      <div class="dialogTitle">
        {{ $t("notice") }}
      </div>
      <div class="bodyContent">
        <div class="tipsIcon">
          <span class="iconfont_Me icon-remind iconStyle"></span>
        </div>
        <div class="tips">
          <div class="tipsTitle">
            {{
              type == 1
                ? $t("noCameraAuthorized")
                : type == 2
                ? $t("noAudioAuthorized")
                : $t("noShareAuthorized")
            }}
          </div>
          <div class="tipsContent">
            <template v-if="isMac">
              <template v-if="type == 1">
                <template v-if="systemPermissions_camera">
                  {{ $t("videoHasBeenDeniedTips") }}
                </template>
                <template v-else>
                  {{ $t("cameraAuthorizedTips_mac") }}
                  <span class="stress" @click="openSystempreferences">{{
                    $t("systemPreferences")
                  }}</span>
                </template>
              </template>
              <template v-if="type == 2">
                <template v-if="systemPermissions_microphone">
                  {{ $t("audioHasBeenDeniedTips") }}
                </template>
                <template v-else>
                  {{ $t("audioAuthorizedTips_mac") }}
                  <span class="stress" @click="openSystempreferences">{{
                    $t("systemPreferences")
                  }}</span>
                </template>
              </template>
              <template v-if="type == 3">
                {{ $t("shareAuthorizedTips_mac") }}
                <span class="stress" @click="openSystempreferences">{{
                  $t("systemPreferences")
                }}</span>
              </template>
            </template>
            <template v-else>
              {{
                type == 1
                  ? $t("cameraAuthorizedTips_windows")
                  : $t("audioAuthorizedTips_windows")
              }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "EnablePermissionsDialog",
  data() {
    return {
      dialogVisible: true,
      isMac: false,
    };
  },
  mounted() {
    this.isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  },
  computed: {
    type() {
      return this.enablePermissionsDialog
        ? this.enablePermissionsDialog.type
        : 1;
    },
    enablePermissionsDialog() {
      return this.$store.state.multimedia_meetingmode.enablePermissionsDialog;
    },
    // 系统摄像头权限
    systemPermissions_camera() {
      return this.$store.state.multimedia_meetingmode
        .cameraIsAuthorizedBySystem;
    },
    // 系统麦克风权限
    systemPermissions_microphone() {
      return this.$store.state.multimedia_meetingmode
        .microphoneIsAuthorizedBySystem;
    },
  },
  methods: {
    dialogBeforeClose() {
      this.$store.commit("setEnablePermissionsDialog", null);
    },
    // 打开信息面板
    openSystempreferences() {
      let command =
        "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera";
      switch (this.type) {
        case 1:
          command =
            "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera";
          break;
        case 2:
          command =
            "x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone";
          break;
        case 3:
          command =
            "x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture";
          break;
        default:
          break;
      }
      window.open(command, "_self");
    },
  },
};
</script>
<style lang="stylus" scoped>
.enablePermissionsDialog
  .dialogBody
    padding-bottom: 50px;
    .dialogTitle
      width: 100%;
      padding 17px 0 20px 0;
      text-align: center;
      font-size: 20px;
      color: #000000;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.08px;
    .bodyContent
      width: 100%;
      min-height: 18px;
      box-sizing: border-box;
      padding 0 47px;
      display: flex;
      align-items: flex-start;
      .tipsIcon
        width: 18px;
        min-height: 18px;
        flex-shrink: 0;
        .iconStyle
          font-size: 20px;
          color: #FF8A00;
      .tips
        flex: 1;
        box-sizing: border-box;
        padding-left: 10px;
        letter-spacing: 0.6px;
        .tipsTitle
          font-size: 16px;
          font-weight: 700;
          color: #000000;
          line-height: 20px;
        .tipsContent
          margin-top: 9px;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          .stress
            color: #33CC66;
            cursor pointer;
</style>
<style lang="stylus">
.enablePermissionsDialog
  border-radius: 20px
  .el-dialog__body
    padding 0 !important;
  .el-dialog__headerbtn
    top: 10px;
    right: 15px
</style>
